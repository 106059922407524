import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = { class: "card-body pt-0" }
const _hoisted_7 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }
const _hoisted_8 = { class: "table-responsive" }
const _hoisted_9 = {
  class: "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",
  role: "grid"
}
const _hoisted_10 = { class: "odd" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DateTime = _resolveComponent("DateTime")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-300px ps-15",
            placeholder: "Pesquisar cliente por email"
          }, null, 544), [
            [_vModelText, _ctx.search]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("table", _hoisted_9, [
            _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
              _createElementVNode("tr", {
                class: "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0",
                role: "row"
              }, [
                _createElementVNode("th", null, "E-mail"),
                _createElementVNode("th", null, "Dispositivos"),
                _createElementVNode("th", null, "Vendas"),
                _createElementVNode("th", null, "Assinaturas"),
                _createElementVNode("th", null, "Data de Cadastro"),
                _createElementVNode("th", null, "Último Login - Painel"),
                _createElementVNode("th", null, "Último Login - Dispositivo")
              ])
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (customer) => {
              return (_openBlock(), _createElementBlock("tbody", {
                class: "fw-bold text-gray-600",
                key: customer.id
              }, [
                _createElementVNode("tr", _hoisted_10, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_router_link, {
                      to: `/apps/customers/customer-details/${customer.id}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(customer.email), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _createElementVNode("td", null, _toDisplayString(customer.mobiles.length), 1),
                  _createElementVNode("td", null, _toDisplayString(customer.orders.length), 1),
                  _createElementVNode("td", null, _toDisplayString(customer.subscriptions.length), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_DateTime, {
                      "date-time": customer.created_at
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_DateTime, {
                      "date-time": customer.last_login_panel
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_DateTime, {
                      "date-time": customer.last_login_device
                    }, null, 8, ["date-time"])
                  ])
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" }, "   ", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_el_pagination, {
              "current-page": _ctx.pagination.page,
              "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pagination.page) = $event)),
              onCurrentChange: _ctx.load,
              "page-size": 10,
              layout: "prev, pager, next",
              total: _ctx.pagination.total,
              "hide-on-single-page": true,
              background: ""
            }, null, 8, ["current-page", "onCurrentChange", "total"])
          ])
        ])
      ])
    ])
  ]))
}